import {computed, reactive} from 'vue'
import router from "@/router";
import statsStore from '@/stores/stats';
import axios from "axios";

/*export type User = { id: number; username: string }
export type UserList = Array<User & { password: string }>*/

const state = reactive({
    username: '',
    id: 0,
    error: '',
})

const getters = reactive({
    username: computed(() => state.username),
    id: computed(() => state.id),
    isLoaded: computed(() => state.username != ''),
})

const actions = {

    async init(name: string) {
        console.log('init!');
        return axios.get('/backend/api/user/' + name).then(({data}: any) => {
            console.log('load!!!');
            statsStore.loadStats(data.id);
            state.username = data.name;
            state.error = '';
            state.id = data.id;
            //     router.push({name: 'Tracks'})
        }).catch(({response: {data}}: any) => {
            state.error = 'Could not find user.'
            return false
        })
    },

    unload() {
        state.username = '';
        state.id = 0;
    }

}

export default {state, getters, ...actions}