import {computed, reactive} from 'vue'
import router from "@/router";
import axios from "axios";

export type Stats = { totalDistance: number, maxElevation: object | null, speedAverage: number }

const state = reactive({
    stats: {totalDistance: 0, maxElevation: null, speedAverage: 0}
})

const getters = reactive({
    totalDistance: computed(() => {
        return state.stats.totalDistance
    }),
    maxElevation: computed(() => state.stats.maxElevation != null ? state.stats.maxElevation.max_elevation : 0),
    speedAverage: computed(() => state.stats.speedAverage),
})

const actions = {
    loadStats(id?: number) {
        console.log('load!');
        return axios.get('/backend/api/stats' + (id ? ("?user_id=" + id) : "")).then(({data}: any) => {
            console.log(data);
            state.stats = data;

        }).catch(({response: {data}}: any) => {
            console.log(data);
        })
    },
}

export default {state, getters, ...actions}