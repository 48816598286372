
    import {defineComponent, ref, inject, onMounted, reactive, nextTick} from "vue";
    import {AxiosError} from "axios";
    //   import userStore from '@/stores/user';
    import {Track} from '@/types/Track';
    import {Picture} from '@/types/Picture';
    import router from "@/router";
    import {useRouter, useRoute} from 'vue-router';
    import MediaOverlay from "@/components/MediaOverlay.vue";
    import {container, openModal, closeModal} from "jenesius-vue-modal";
    import {minutesToReadable, hoursToReadable} from "@/general";
    import Vue3ChartJs from '@j-t-mcc/vue3-chartjs'

    import gpxParser from 'gpxparser';

    export default defineComponent({
        name: "TrackView",
        components: {
            MediaOverlay, Vue3ChartJs,
        },
        setup() {
            const center = ref([40, 40]);
            const projection = ref('EPSG:4326');
            const zoom = ref(14);
            const rotation = ref(0);
            const format = inject('ol-format');
            const gpx = new (format as any).GPX();
            const olSource = ref("https://tile.thunderforest.com/atlas/{z}/{x}/{y}.png?apikey=de1df39a38684d26a8fa0297eec5a013");
            const markerIcon = ref("/images/marker.png");
            const track: Track | null = reactive({
                id: 0,
                time_start: "",
                time_end: "",
                total_time: 0,
                distance: 0,
                elevation_max: 0,
                elevation_min: 0,
                elevation_average: 0,
                description: "",
                title: null,
                speed_average: 0,
                center_lng: null,
                center_lat: null,
                gpx_file_name: "",
                gpx_file_path: "",
                pictures: []
            });
            const axios: any = inject('axios')  // eslint-disable-next-line
            const route = useRoute()

            const lineChart = reactive({
                type: "line", options: {responsive: true,}, data: {
                    labels: [],
                    datasets: [
                        {
                            label: 'Altitude (m)',
                            data: [],
                            borderColor: '#E63946',
                            fill: false,
                        }
                    ]
                },
                scales: {
                    x: {
                        display: true,
                        title: {
                            display: true
                        }
                    },
                    y: {
                        display: true,
                        title: {
                            display: true,
                            text: 'Value'
                        },
                        suggestedMin: -10,
                        suggestedMax: 200
                    }
                }
            });
            const chartRef = ref(null);

            const altitudeArray: number[] = reactive([]);
            const altitudeLabels: any[] = reactive([]);

            function loadFromAPI() {
                return axios.get('/backend/api/track/' + route.params.id).then(({data}: any) => {
                    console.log(data);
                    //  track.value = data;
                    Object.assign(track, data);
                    if (track.center_lng && track.center_lat)
                        center.value = [track.center_lng, track.center_lat];
                    nextTick(() => {
                        if (track.title)
                            document.title = track.title + " BKE";
                    })

                    fetch('/backend/api/get-file?path=' + track.gpx_file_path)
                        .then(response => response.text())
                        .then(data => {
                            // Do something with your data
                            //    console.log(data);
                            gParser.parse(data);
                            console.log(gParser, gParser.tracks[0].distance.total);

                            let increment = Math.round(gParser.tracks[0].points.length / 50);
                            let maxLng = 0;
                            let minLng = 0;

                            for (let i = 0; i < gParser.tracks[0].points.length; i = i + increment) {
                                // console.log(i);
                                    console.log(gParser.tracks[0].points[i].ele);
                                //    console.log(altitudeArray);
                                altitudeArray.push(gParser.tracks[0].points[i].ele);
                                altitudeLabels.push('');
//calculate extremes in Y
                                if (i == 0) {
                                    maxLng = gParser.tracks[0].points[i].lon;
                                    minLng = gParser.tracks[0].points[i].lon;
                                } else {
                                    if (gParser.tracks[0].points[i].lon > maxLng)
                                        maxLng = gParser.tracks[0].points[i].lon;
                                    if (gParser.tracks[0].points[i].lon < minLng)
                                        minLng = gParser.tracks[0].points[i].lon;

                                }
                            }

                            //console.log('dif', maxLng - minLng);
                            let latRange = maxLng - minLng;
                            zoom.value = Math.round(15.25 - (latRange * 25));//I hope this formula will make me millionaire or something
                            //    console.log(zoom.value);

                            Object.assign(lineChart.data, {
                                labels: altitudeLabels,
                                datasets: [
                                    {
                                        label: 'Altitude (m)',
                                        data: altitudeArray,
                                        borderColor: '#E63946',
                                        fill: false,
                                    }
                                ]
                            });


                            chartRef.value.update(250)

                        });

                }).catch(({response: {data}}: any) => {
                    console.log(data);
                })
            }

            loadFromAPI();

            function displayPicture(index: number) {
                console.log(index, track.pictures);
                let picture: Picture = track.pictures[index];
                console.log(index, picture);
                let mediaModal = openModal(MediaOverlay, {
                    type: "image",
                    file: "/backend/api/get-file?path=" + picture.file_path,
                    youtubeId: null,
                    description: picture.description
                    //       format: items[index].format
                });
            }

            const emitter = inject("emitter");
            (emitter as any).on('close-media', (data: any) => {

                closeModal();

            });


            const selectConditions: any = inject('ol-selectconditions')
            const selectCondition = selectConditions.singleClick;
            const selectInteractionFilter = (feature: any) => {
                // console.log(feature.values_);
                //this is all done just to capture a damn click
                if (feature.values_.type && feature.values_.type == 'photo')
                    displayPicture(feature.values_.index);
                return false;
            };

            function formatDate(dateString: string, compact: boolean): string {
                console.log(dateString);
                const date = new Date(dateString);
                // Then specify how you want your dates to be formatted
              //  console.log(date);
                return new Intl.DateTimeFormat('en-AU', {
                    day: 'numeric',
                    month: (compact ? 'short' : 'long'),
                    year: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric'
                }).format(date);
            }

            let gParser = new gpxParser(); //Create gpxParser Object


            return {
                track,
                center,
                projection,
                zoom,
                rotation,
                format,
                gpx,
                olSource,
                markerIcon,
                displayPicture,
                selectInteractionFilter,
                selectCondition,
                formatDate,
                minutesToReadable,
                hoursToReadable,
                lineChart,
                altitudeArray,
                chartRef

            };
        }
    });
