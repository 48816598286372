import {createApp} from 'vue';
import App from './App.vue';
import router from './router';
import userStore from '@/stores/user';
import ViewUserStore from '@/stores/view_user';

const OpenLayersMap = require('vue3-openlayers');
import 'vue3-openlayers/dist/vue3-openlayers.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import "./assets/scss/main.scss"
import axios from "axios";
import Notifications from '@kyvg/vue3-notification'
import mitt from 'mitt';
import VCalendar from 'v-calendar';

const VueAxios = require("vue-axios/dist/vue-axios.common.min");
//const store = require('./store');
//import { store, key } from './store'

const emitter = mitt();

const app = createApp(App).use(router).use(OpenLayersMap);
app.use(VueAxios, axios)
app.use(Notifications)
app.provide('axios', app.config.globalProperties.axios)  // provide 'axios'
app.provide('emitter', emitter);
app.config.globalProperties.emitter = emitter;
app.use(VCalendar, {})
app.mount('#app');

axios.defaults.withCredentials = true;

axios.interceptors.response.use(function (response) {
    return response
}, function (error) {
    //  console.log(error.response.data)
    if (error.response.status === 401) {
        userStore.logout();

        router.push('/login')
    }
    return Promise.reject(error)
})


