import {computed, reactive} from 'vue'
import router from "@/router";
import statsStore from '@/stores/stats';
import ViewUserStore from "@/stores/view_user";

export type User = { id: number; username: string }
export type UserList = Array<User & { password: string }>

const state = reactive({
    username: '',
    id: 0,
    error: '',
    authenticated: false,
})

const getters = reactive({
    isLoggedIn: computed(() => state.username !== ''),
    username: computed(() => state.username),
    id: computed(() => state.id),
})

const actions = {

    async login(username: string, id: number) {
        //  return axios.get('/api/user').then(({data}: any) => {
        await statsStore.loadStats();
        state.username = username;
        state.error = '';
        state.id = id;
        state.authenticated = true;
        ViewUserStore.unload();//forget user in view
        router.push({name: 'AdminTracks'})
        /*  }).catch(({response: {data}}: any) => {
              state.error = 'Could not find user.'
              return false
          })*/
    },
    async logout() {
        state.authenticated = false;
        state.username = '';
        state.id = 0;
    }
}

export default {state, getters, ...actions}