
    import {defineComponent, ref, inject, onMounted} from "vue";
    import Errors from '../components/Errors.vue';
    import {AxiosError} from "axios";
    import userStore from '@/stores/user';


    export default defineComponent({
        name: "Login",
        emits: ['logged'],
        components: {Errors},
        setup() {
            const axios: any = inject('axios')  // eslint-disable-next-line

            const username = ref(null);
            const password = ref(null);
            const processing = ref(false);

            const errors_comp = ref();

            async function login() {
                errors_comp.value.resetErrors();
                if (username.value == null || username.value == '') {
                    errors_comp.value.pushError("Empty username!");
                    return;
                }
                if (password.value == null || password.value == '') {
                    errors_comp.value.pushError("Empty password!");
                    return;
                }

                processing.value = true;
                await axios.get('backend/api/sanctum/csrf-cookie')
                await axios.post('backend/api/login', {
                    name: username.value,
                    password: password.value
                }).then((response: { data: { user: { name: string, email: string | null, id: number } } }) => {

                    userStore.login(response.data.user.name, response.data.user.id);
                }).catch((err: AxiosError) => {
                    console.log((err.response as any).status);
                    if ((err.response as any).status == 401)
                        errors_comp.value.pushError("Wrong username or password!");
                    //  console.log(err.request);
                    //errors_comp.value?.pushError("dalek");
                }).finally(() => {
                    processing.value = false
                })
            }

            return {username, password, processing, login, errors_comp};
        },
    });
