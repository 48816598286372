
    import {defineComponent, ref, inject, onMounted, reactive,} from "vue";
    import Errors from '../components/Errors.vue';
    import {AxiosError} from "axios";
    import userStore from '@/stores/user';
    import {Track} from '@/types/Track';
    import router from "@/router";
    import {useRouter, useRoute} from 'vue-router'
    import {notify} from "@kyvg/vue3-notification";


    export default defineComponent({
        name: "EditTrack",
        setup() {
            const center = ref([40, 40]);
            const projection = ref('EPSG:4326');
            const zoom = ref(14);
            const rotation = ref(0);
            const format = inject('ol-format');
            const gpx = new (format as any).GPX();
            const olSource = ref("https://tile.thunderforest.com/atlas/{z}/{x}/{y}.png?apikey=de1df39a38684d26a8fa0297eec5a013");
            const markerIcon = ref("/images/marker.png");
            const track: Track = reactive({
                id: 0,
                time_start: "",
                time_end: "",
                total_time: 0,
                distance: 0,
                elevation_max: 0,
                elevation_min: 0,
                elevation_average: 0,
                description: "",
                title: null,
                speed_average: 0,
                center_lng: null,
                center_lat: null,
                gpx_file_name: "",
                gpx_file_path: "",
                pictures: []
            });
            const isLoading = ref(false);
            const axios: any = inject('axios')  // eslint-disable-next-line
            const router = useRouter()
            const route = useRoute()
            const vectorsource = ref(null)


            function loadFromAPI() {
                return axios.get('/backend/api/track/' + route.params.id).then(({data}: any) => {
                    console.log(data);
                    //  track.value = data;
                    Object.assign(track, data);
                    if (track.center_lng && track.center_lat)
                        center.value = [track.center_lng, track.center_lat];

                }).catch(({response: {data}}: any) => {
                    console.log(data);
                })
            }

            loadFromAPI();

            function updateTrack() {
                isLoading.value = true;
                axios.put('/backend/api/track/' + track.id, {
                    title: track.title,
                    description: track.description
                }).then((response: { data: { user: any } }) => {
                    notify({
                        title: "👌",
                        type: "success",
                        text: "The track was updated",
                    });
                }).catch((err: AxiosError) => {
                    console.log((err.response as any).status);
                    notify({
                        title: "👎",
                        type: "error",
                        text: "There was an error updating the track",
                    });
                }).finally(() => {
                    isLoading.value = true;
                })
            }

            let newFile: any = null;
            const image_file = ref(null);

            const onChangeFileUpload = (e: any) => {
                console.log(e.target.files[0]);
                newFile = e.target.files[0];
            }

            function uploadImage() {

                let formData = new FormData();

                if (newFile == null) {
                    notify({
                        title: "✋",
                        type: "warning",
                        text: "Missing file!",
                    });
                    return;
                }
                isLoading.value = true;

                formData.append('file', newFile);
                formData.append('track_id', track.id.toString());
                if (track.description)
                    formData.append('description', track.description);

                axios.post('/backend/api/picture',
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                ).then(({data}: any) => {
                    newFile = null;
                    image_file.value = null;
                    isLoading.value = false;
                    notify({
                        title: "👌",
                        type: "success",
                        text: "The picture was created",
                    });

                    loadFromAPI();

                }).catch(({response: {data}}: any) => {
                    notify({
                        title: "👎",
                        type: "error",
                        text: "Something went wrong uploading that image!",
                    });

                    console.log(data);
                    isLoading.value = false;
                });
            }

            const deletePicture = (id: number, index: number) => {
                if (confirm("Delete this picture?")) {
                    axios.delete('/backend/api/picture/' + id, {})
                        .then(({data}: any) => {
                            track.pictures.splice(index, 1);
                            notify({
                                title: "👌",
                                type: "success",
                                text: "The picture was deleted",
                            });
                        }).catch(({response: {data}}: any) => {
                        console.log(data);
                    })
                }
            }


            return {
                center,
                projection,
                zoom,
                rotation,
                track,
                format,
                gpx,
                olSource,
                isLoading,
                updateTrack,
                image_file,
                onChangeFileUpload,
                uploadImage,
                markerIcon,
                vectorsource,
                deletePicture
            }
        }
    });
