<template>
    <transition name="fade">
        <div class="alert alert-danger d-flex align-items-center" role="alert" v-if="errors.length > 0">
            <i class="lni lni-warning"></i> &nbsp;&nbsp;
            <div>
                <div v-for="error in errors" v-bind:key="error">
                    - {{error}}
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    import {defineComponent, ref,} from "vue";

    export default defineComponent({
        name: "Errors",
        setup() {
            const errors = ref([]);

            const pushError = (error) => {
                errors.value.push(error);
            }

            const resetErrors = () => {
                //  Object.assign(errors, []);
                errors.value = [];
            }

            return {errors, pushError, resetErrors};
        },
    });
</script>

<style scoped>
    .lni {
        font-size: 24px;
    }
</style>