
    import {defineComponent, inject, ref} from "vue";
    import {minutesToReadable, formatDate, hoursToReadable} from "@/general.ts";
    import ViewUserStore from "@/stores/view_user";

    export default defineComponent({
        name: "Stats",
        setup() {
            const axios: any = inject('axios')  // eslint-disable-next-line
            const stats = ref(null);

            function loadFromAPI() {
                return axios.get('/backend/api/stats?advanced=1' + (ViewUserStore.getters.isLoaded ? "&user_id=" + ViewUserStore.getters.id : "")).then(({data}: any) => {
                    stats.value = data;
                }).catch(({response: {data}}: any) => {
                    console.log(data);
                })
            }

            loadFromAPI();

            return {stats, minutesToReadable, formatDate, hoursToReadable, ViewUserStore}
        },
    });
