import {computed, reactive} from 'vue'
import router from "@/router";
import axios from "axios";


/**
 * Converts a minutes amount into a 00:00 format
 */
function minutesToReadable(minutes: number): string {
    let seconds = minutes * 60;
    let numhours = Math.floor(((seconds % 31536000) % 86400) / 3600);
    let numminutes = Math.floor((((seconds % 31536000) % 86400) % 3600) / 60);
    let numseconds = (((seconds % 31536000) % 86400) % 3600) % 60;

    return numhours.toString() + ":" + leftPad(numminutes, 2);
}

/**
 * Converts a minutes amount into a 00:00 format
 */
function hoursToReadable(hours: number, compact: boolean): string {
    let seconds = hours * 60 * 60;
    let numhours = Math.floor(((seconds % 31536000) % 86400) / 3600);
    let numminutes = Math.floor((((seconds % 31536000) % 86400) % 3600) / 60);
    // let numseconds = (((seconds % 31536000) % 86400) % 3600) % 60;
    let numDays = Math.floor((seconds % 31536000) / 86400);

    return (numDays > 0 ? +numDays.toString() + (compact ? "d " : " days ") : "") + (numhours > 0 ? +numhours.toString() + (compact ? "h " : " hours ") : "") + (numminutes > 0 ? +numminutes.toString() + (compact ? "m" : " min") : "");
}

/**
 * Adds 0 to number if single digit
 * @param number
 * @param targetLength
 * @return string
 */
function leftPad(number: number, targetLength: number) {
    let output = number + '';
    while (output.length < targetLength) {
        output = '0' + output;
    }
    return output;
}

function formatDate(dateString: string, compact: boolean): string {
    const date = new Date(dateString);
    // Then specify how you want your dates to be formatted
    return new Intl.DateTimeFormat('en-AU', {
        day: 'numeric',
        month: (compact ? 'short' : 'long'),
        year: 'numeric'
    }).format(date);
}

export {minutesToReadable, leftPad, formatDate, hoursToReadable}