
    import {defineComponent, ref, inject} from "vue";
    import {container, openModal, pusModal} from "jenesius-vue-modal";

    export default {
        name: "MediaOverlay",
        components: {},
        props: {
            type: String,
            file: String,
            //    format: String,
            description: String
        },
        setup(props: any, context: any) {
            console.log(props.description);
            const emitter = inject("emitter");

            const emitClose = () => {
                (emitter as any).emit('close-media');
            }

            const video = ref(null);

            const compressedPath = (path: string) => {
                let pathParts = path.split('/');
                return pathParts[0] + "/" + pathParts[1] + "/m4v/" + pathParts[2].replace("mp4", "m4v");
            }

            return {emitClose, compressedPath}
        },


    }
