
    import {defineComponent, ref, inject, reactive, computed} from 'vue';
    import router from "@/router";
    import {Track} from "@/types/Track";
    import {notify} from "@kyvg/vue3-notification";
    import {minutesToReadable, formatDate} from "@/general.ts";
    import ViewUserStore from '@/stores/view_user'
    import UserStore from '@/stores/user';

    //const TableLite = require("vue3-table-lite");
    //  import TableLite from "vue3-table-lite";

    export default defineComponent({
        name: 'Tracks',
        components: {},
        setup() {
            const sortField = ref('time_start');
            const sortAsc = ref(false);
            const isLoading = ref(true);
            const axios: any = inject('axios')  // eslint-disable-next-line
            const tracks = reactive([]);

            function sortBy(field: string) {
                sortField.value = field;
                sortAsc.value = !sortAsc.value;
                switch (field) {
                    case 'time_start':
                        tracks.sort(function (a: Track, b: Track) {
                            var c = new Date(a.time_start).getTime();
                            var d = new Date(b.time_start).getTime();
                            if (sortAsc.value)
                                return c - d;
                            else
                                return d - c;
                        });
                        break;
                    case 'title':
                        tracks.sort(function (a: Track, b: Track) {
                            let at = (a.title ? a.title : "");
                            let bt = (b.title ? b.title : "");
                            if (at < bt) {
                                if (sortAsc.value)
                                    return -1;
                                else
                                    return 1;
                            }
                            if (at > bt) {
                                if (sortAsc.value)
                                    return 1;
                                else
                                    return -1;
                            }
                            return 0;
                        });
                        break;
                    case 'total_time':
                    case 'distance':
                    case 'elevation_max':
                    case 'speed_average':
                        tracks.sort(function (a: Track, b: Track) {

                            if (a[field] < b[field]) {
                                console.log(a[field], "<", b[field]);
                                if (sortAsc.value)
                                    return -1;
                                else
                                    return 1;
                            }
                            if (a[field] > b[field]) {
                                console.log(a[field], ">", b[field]);
                                if (sortAsc.value)
                                    return 1;
                                else
                                    return -1;
                            }
                            return 0;
                        });
                        break;
                }
            }

            function loadFromAPI() {
                console.log(ViewUserStore.getters.isLoaded, ViewUserStore.getters.username, ViewUserStore.getters.id);
                return axios.get('/backend/api/track' + (ViewUserStore.getters.isLoaded ? "?user_id=" + ViewUserStore.getters.id : "")).then(({data}: any) => {
                    console.log(data);
                    //tracks.values  = data;
                    Object.assign(tracks, data)

                }).catch(({response: {data}}: any) => {
                    console.log(data);
                })
            }

            loadFromAPI();


            function trimText(text: string): string {
                let limit = 25;
                return (text.length > limit ? text.substring(0, 25) + "…" : text);
            }


            const deleteTrack = (id: number, index: number) => {
                if (confirm("Delete this track?")) {
                    axios.delete('/backend/api/track/' + id, {})
                        .then(({data}: any) => {
                            tracks.splice(index, 1);
                            notify({
                                title: "👌",
                                type: "success",
                                text: "The track was deleted",
                            });
                        }).catch(({response: {data}}: any) => {
                        console.log(data);
                    })
                }
            }

            function canEdit() {
                if (tracks.length == 0)
                    return false;
                return (UserStore.getters.isLoggedIn && tracks[0].user_id == UserStore.getters.id);
            }

            return {
                sortField,
                isLoading,
                sortBy,
                sortAsc,
                loadFromAPI,
                tracks,
                formatDate,
                trimText,
                minutesToReadable,
                deleteTrack,
                canEdit,
                ViewUserStore
            }
        },

    });
