import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
//import Home from '../views/Home.vue'
import Login from "@/views/Login.vue";
import userStore from '@/stores/user';
import ViewUserStore from '@/stores/view_user';
import Tracks from "@/views/Tracks.vue";
import UploadGPX from "@/views/UploadGPX.vue";
import EditTrack from "@/views/EditTrack.vue";
import TrackView from "@/views/TrackView.vue";
import DailyActivities from "@/views/DailyActivities.vue";
import Stats from "@/views/Stats.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'Tracks',
        component: Tracks,
        meta: {
            middleware: "auth",
            title: "Tracks"
        },
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            middleware: "guest",
            title: "Login"
        },
    },
    {
        path: '/tracks',
        name: 'AdminTracks',
        component: Tracks,
        meta: {
            middleware: "auth",
            title: "Tracks"
        },
    },
    {
        path: '/upload-gpx',
        name: 'UploadGpx',
        component: UploadGPX,
        meta: {
            middleware: "auth",
            title: "Upload GPX"
        },
    },
    {
        path: '/edit-track/:id',
        name: 'EditTrack',
        component: EditTrack,
        meta: {
            middleware: "auth",
            title: "Edit track"
        },
    },
    {
        path: '/track/:id',
        name: 'AdminTrackView',
        component: TrackView,
        meta: {
            middleware: "auth",
            title: "View Track"
        },
    },
    {
        path: '/view-user/:user_name/track/:id',
        name: 'TrackView',
        component: TrackView,
        meta: {
            middleware: "guest",
            title: "View Track"
        },
    },
    {
        path: '/daily-activities',
        name: 'AdminDailyActivities',
        component: DailyActivities,
        meta: {
            middleware: "auth",
            title: "Daily Activities"
        },
    },
    {
        path: '/view-user/:user_name/daily-activities',
        name: 'DailyActivities',
        component: DailyActivities,
        meta: {
            middleware: "guest",
            title: "Daily Activities"
        },
    },
    {
        path: '/view-user/:user_name/stats',
        name: 'AdminStats',
        component: Stats,
        meta: {
            middleware: "guest",
            title: "Stats"
        },
    },
    {
        path: '/view-user/:user_name/stats',
        name: 'AdminStats',
        component: Stats,
        meta: {
            middleware: "guest",
            title: "Stats"
        },
    },
    {
        path: '/stats',
        name: 'Stats',
        component: Stats,
        meta: {
            middleware: "auth",
            title: "Stats"
        },
    },
    {
        path: '/view-user/:user_name',
        name: 'ViewUser',
        component: Tracks,
        meta: {
            middleware: "guest",
            title: "View User"
        },
    },
    {
        path: '/view-user/:user_name/tracks',
        name: 'Tracks',
        component: Tracks,
        meta: {
            middleware: "guest",
            title: "Tracks"
        },
    },


]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    document.title = `${to.meta.title} - BKE`;
    //deal with user in display

    if (to.meta.middleware == "guest") {
        /*  if (userStore.getters.isLoggedIn) {
              next({name: "dashboard"})
          }*/
        //  next()
    } else { //auth
        if (userStore.getters.isLoggedIn) {
            // next()
        } else {
            next({name: "Login"})
        }
    }

    if (to.params.user_name) {
        document.title = `${to.meta.title} of ${to.params.user_name} - BKE`
        if (ViewUserStore.getters.username != to.params.user_name)//mea
        { // @ts-ignore
            ViewUserStore.init(to.params.user_name).then(response => {
                next();
            });
        } else
            next();
    } else
        next();


})

export default router
