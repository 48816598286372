
    import {defineComponent, ref, inject, reactive, computed} from 'vue';
    import router from "@/router";
    import {Track} from "@/types/Track";
    import {notify} from "@kyvg/vue3-notification";
    import axios from "axios";
    import Errors from '../components/Errors.vue';

    //const TableLite = require("vue3-table-lite");
    //  import TableLite from "vue3-table-lite";

    export default defineComponent({
        name: "UploadGPX",
        setup(props, context) {

            const isLoading = ref(false);
            const axios: any = inject('axios')  // eslint-disable-next-line
            let newFile: any = null;
            const gpx_file = ref(null);

            const onChangeFileUpload = (e: any) => {
                console.log(e.target.files);
                //  console.log(gpx_file);
                newFile = e.target.files[0];
            }

            function uploadFile() {
                let formData = new FormData();

                if (newFile == null) {
                    notify({
                        title: "✋",
                        type: "warning",
                        text: "Missing file!",
                    });
                    return;
                }
                isLoading.value = true;

                formData.append('file', newFile);

                axios.post('/backend/api/track',
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                ).then(({data}: any) => {

                    newFile = null;
                    gpx_file.value = null;
                    isLoading.value = false;
                    notify({
                        title: "👌",
                        type: "success",
                        text: "The track was created",
                    });

                    router.push({path: '/edit-track/' + data.id})

                }).catch(({response: {data}}: any) => {
                    notify({
                        title: "👎",
                        type: "error",
                        text: "Something went wrong uploading that GPX!",
                    });

                    console.log(data);
                    isLoading.value = false;
                });


            }

            return {uploadFile, onChangeFileUpload, isLoading}
        }
    });
