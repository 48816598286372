
    import {defineComponent, ref, reactive, nextTick, inject} from "vue";
    import {DailyActivities} from '@/types/DailyActivities';
    import userStore from '@/stores/user';
    import ViewUserStore from '@/stores/view_user';
    import {AxiosError} from "axios";
    import {notify} from "@kyvg/vue3-notification";
    import UserStore from "@/stores/user";


    export default defineComponent({

        name: "DailyActivities",
        setup() {
            const axios: any = inject('axios')  // eslint-disable-next-line
            let today = new Date();
            let userId = userStore.getters.id;
            const isLoading = ref(false);
            const date = ref(today.toJSON().slice(0, 10));
            const currentActivities = reactive({
                id: null,
                new_prefectures: 0,
                dogs_seen: 0,
                money_spent: 0,
                accomodation: null,
                user_id: userId,
                date: date.value,
            });

            //   const
            const attributes = ref([{}]);
            attributes.value.splice(0, 1);//ts jesus
            const modelConfig = reactive({
                type: 'string',
                mask: 'YYYY-MM-DD', // Uses 'iso' if missing
            });

            function loadActivities() {
                return axios.get('/backend/api/daily-activity' + (ViewUserStore.getters.isLoaded ? "?user_id=" + ViewUserStore.getters.id : "")).then(({data}: any) => {
                    attributes.value = [];
                    data.forEach((element: any) => {
                        attributes.value.push({
                            dot: true as boolean,
                            // content: '#457B9D',
                            customData: element,
                            dates: new Date(element.date.replace('-', '/')),
                            //  highlight: true,
                            order: 1000,
                            key: element.id,
                        });
                        console.log(date.value, element.date);
                        if (date.value == element.date)
                            Object.assign(currentActivities, element);

                    });

                }).catch(({response: {data}}: any) => {
                    console.log(data);
                })
            }

            loadActivities();

            function dayClicked(day: any) {
                console.log(day);
                if (day.attributes.length > 0) {
                    currentActivities.id = day.attributes[0].customData.id;
                    currentActivities.new_prefectures = day.attributes[0].customData.new_prefectures;
                    currentActivities.dogs_seen = day.attributes[0].customData.dogs_seen;
                    currentActivities.money_spent = day.attributes[0].customData.money_spent;
                    currentActivities.accomodation = day.attributes[0].customData.accomodation;
                    currentActivities.date = day.attributes[0].customData.date;
                } else {
                    currentActivities.id = null
                    currentActivities.new_prefectures = 0;
                    currentActivities.dogs_seen = 0;
                    currentActivities.money_spent = 0;
                    currentActivities.accomodation = null;
                    currentActivities.date = day.id;

                }
            }


            function saveActivities() {
                axios.post('backend/api/daily-activity' + (currentActivities.id ? "/" + currentActivities.id : ""), currentActivities).then((response: { data: any }) => {
                    notify({
                        title: "✋",
                        type: "success",
                        text: "Daily activities were saved",
                    });
                    loadActivities();
                }).catch((err: AxiosError) => {
                    console.log((err.response as any).status);
                    notify({
                        title: "✋",
                        type: "error",
                        text: "There was an error saving these daily activities!",
                    });
                    //  console.log(err.request);
                    //errors_comp.value?.pushError("dalek");
                }).finally(() => {
                    isLoading.value = false
                })
            }

            function canEdit() {
                console.log(ViewUserStore.getters.isLoaded, UserStore.getters.isLoggedIn, ViewUserStore.getters.id, UserStore.getters.id);
                if (!ViewUserStore.getters.isLoaded)
                    return true;
                if (UserStore.getters.isLoggedIn)
                    return (ViewUserStore.getters.id == UserStore.getters.id)
                return false;
            }


            return {date, attributes, modelConfig, dayClicked, currentActivities, saveActivities, isLoading, canEdit}
        }
    });
