

    import {defineComponent, inject, ref} from "vue";
    import userStore from '@/stores/user';
    import {useRoute} from 'vue-router'
    import {computed} from 'vue'
    import {AxiosError} from "axios";
    import router from "@/router";
    import {notify} from "@kyvg/vue3-notification";
    import statsStore from '@/stores/stats';
    import ViewUserStore from '@/stores/view_user';
    import {container} from "jenesius-vue-modal";


    export default defineComponent({
        name: "App",
        /*  props: {
              admin: Boolean
          },*/
        /*  emits: ['logged'],*/
        components: {WidgetContainerModal: container},
        setup() {

            const axios: any = inject('axios')  // eslint-disable-next-line
            const sideMenuToggled = ref(false);
            const toggleMenu = () => {
                sideMenuToggled.value = !sideMenuToggled.value;
                console.log(statsStore.getters.totalDistance);
            }

            const bigScreen = () => {
                return (document.body.clientWidth > 1200);
            }

            const toggleButtonIcon = () => {
                //  if (bigScreen()) {
                if (sideMenuToggled.value)
                    return "lni-menu";
                else
                    return "lni-chevron-left";

            }

            const route = useRoute();

            const path = computed(() => route.path)

            const logout = () => {
                axios.post('backend/api/logout', {}).then((response: { data: any }) => {
                    userStore.logout();
                    router.push({name: "Login"});
                }).catch((err: AxiosError) => {
                    console.log((err.response as any).status);
                })
            }

            function redirect(url: string, hasViewVersion: boolean) {
                sideMenuToggled.value = false;
                let path = ((hasViewVersion && ViewUserStore.getters.isLoaded) ? '/view-user/' + ViewUserStore.getters.username : '') + url;
                console.log(path);
                router.push({path: path});
            }


            return {
                sideMenuToggled,
                toggleMenu,
                bigScreen,
                toggleButtonIcon,
                userStore,
                path,
                logout,
                statsStore,
                ViewUserStore,
                redirect
            }
        }

    })
